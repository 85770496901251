import React from "react"
import MiscContent from "./layouts/MiscContent"
import HomepageHero from "./layouts/HomepageHero/HomepageHero"
import VideoHero from "./layouts/VideoHero/VideoHero"
import ServicesGrid from "./layouts/ServicesGrid/ServicesGrid"
import ServiceTiles from "./layouts/ServiceTiles/ServiceTiles"
import ContentCarousel from "./layouts/ContentCarousel/ContentCarousel"
import ImageHighlight from "./layouts/ImageHighlight/ImageHighlight"
import HalfContentGrid from "./layouts/HalfContentGrid/HalfContentGrid"
import StaffList from "./layouts/StaffList/StaffList"
import AllPosts from "./layouts/AllPosts/AllPosts"
import BlockContent from "./layouts/BlockContent/BlockContent"
import ImageGallery from "./layouts/ImageGallery/ImageGallery"
import PressReleases from "./layouts/PressReleases/PressReleases"
import AllResources from "./layouts/AllResources/AllResources"

export const getPageLayout = (layout, lightContentArea) => {
  const layoutName = layout?.fieldGroupName?.split("_").pop().trim()
  if (!layoutName) return null
  switch (layoutName) {
    case "MiscContent":
      return <MiscContent {...layout} />
    case "HomepageHero":
      return <HomepageHero {...layout} />
    case "VideoHero":
      return <VideoHero {...layout} />
    case "ContentCarousel":
      return <ContentCarousel {...layout} />
    case "ServicesGrid":
      return <ServicesGrid {...layout} lightContentArea={lightContentArea} />
    case "ServiceTiles":
      return <ServiceTiles {...layout} lightContentArea={lightContentArea} />
    case "ImageHighlight":
      return <ImageHighlight {...layout} lightContentArea={lightContentArea} />
    case "HalfContentGrid":
      return <HalfContentGrid {...layout} lightContentArea={lightContentArea} />
    case "BlockContent":
      return <BlockContent {...layout} lightContentArea={lightContentArea} />
    case "StaffList":
      return <StaffList {...layout} lightContentArea={lightContentArea} />
    case "AllPosts":
      return <AllPosts {...layout} lightContentArea={lightContentArea} />
    case "ImageGallery":
      return <ImageGallery {...layout} lightContentArea={lightContentArea} />
    case "PressReleases":
      return <PressReleases {...layout} lightContentArea={lightContentArea} />
    case "AllResources":
      return <AllResources {...layout} lightContentArea={lightContentArea} />
    default:
      return null
  }
}
