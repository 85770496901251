import React from "react"
import * as S from "./ImageGallery.styles.jsx"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
    //   backgroundColor: theme.palette.background.paper,
    },
    imageList: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    }
}));

const ImageGallery = ({ title, items, lightContentArea }) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <S.Wrapper lightContentArea={lightContentArea}>
        {title &&
        <S.SectionTitle lightContentArea={lightContentArea}>{parse(title)}</S.SectionTitle>
        }

        {items && (
            <ImageList rowHeight={ isSmallScreen ? 250 : 300} className={classes.imageList} cols={ isSmallScreen ? 2 : 4}>
            {items.map((item, index) => (
                <ImageListItem key={index} cols={ isSmallScreen ? 1.8 : 1.3}>
                    <GatsbyImage image={item.image.localFile.childImageSharp.gatsbyImageData} alt={item.image.altText || ""} style={{height: "100%"}} />
                </ImageListItem>
            ))}
            </ImageList>
        )}
    </S.Wrapper>
  )
}
export default ImageGallery