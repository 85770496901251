import styled from "styled-components"
import Container from "@material-ui/core/Container"
import { Grid } from "@material-ui/core"

export const Wrapper = styled(Container)`
  padding-bottom: 1em;
  background-color: ${props => props.lightContentArea ? "#F4F8FE" : "#000" };
  color: ${props => props.lightContentArea ? "#000" : "#FFF" };

  .peopleList {
    margin-top: 3rem;
  }
`
export const Person = styled(Grid)`
  margin-bottom: 2rem;

  .portrait {
    text-align: center;
  }

  & > hr {
    background-color: ${props => props.lightContentArea ? "#FFF" : "#0096c6" };

    ${({ theme }) => theme.breakpoints.down("xs")} {
      display: none;
    }
  }
`

export const Name = styled.h4`
  font-size: 2em;
  line-height: .8em;
  letter-spacing: .01em;
  color: ${props => props.lightContentArea ? "#0096c6" : "#FFF" };
  
  small {
    display: block;
    text-transform: none;
    font-size: .9rem;
    letter-spacing: normal;
    color: ${props => props.lightContentArea ? "#004b63" : "#CCC" };
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    text-align: center;
  }
`

export const Biography = styled.div`
  font-size: .9em;

  ${({ theme }) => theme.breakpoints.down("xs")} {
    text-align: center;
  }
`