import React from "react"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import LearnCard from "../../components/learn-card/learn-card.component"
import { graphql, useStaticQuery } from "gatsby"

const AllResources = ({ title, lightContentArea }) => {
  const data = useStaticQuery(graphql`
    {
      allWpPost(
        filter: {
          categories: {
            nodes: { elemMatch: { slug: { ne: "press-releases" } } }
          }
        }
        sort: { fields: date, order: DESC }
      ) {
        edges {
          node {
            featuredImage {
              node {
                altText
                sourceUrl
                title
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, quality: 100)
                  }
                }
              }
            }
            categories {
              nodes {
                name
              }
            }
            content
            slug
            title
          }
        }
      }
    }
  `)

  const postsData = data.allWpPost.edges

  if (!postsData) return null

  return (
    <>
      <div>
        <Container contained>
          <h2>{title}</h2>
          <Grid container spacing={2}>
            {postsData.map(post => (
              <Grid item xs={12} md={4}>
                <LearnCard
                  isLight={lightContentArea}
                  image={
                    post.node.featuredImage && post.node.featuredImage.node
                  }
                  tag={post.node.categories.nodes[0].name}
                  url={`/resources/${post.node.slug}`}
                  title={post.node.title}
                  text={post.node.content}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </>
  )
}
export default AllResources
