import React from "react"
import * as S from "./ImageHighlight.styles.jsx"
import parse from "html-react-parser"

const ImageHighlight = ({ title, content, image, flip, lightContentArea, classList }) => {
  return (
    <S.Wrapper lightContentArea={lightContentArea} className={classList}>
      <S.HighlightContainer>
        <S.ImageHighlight container justify="center" alignItems="stretch" spacing={0} direction={flip ? 'row-reverse' : 'row'}>
          <S.Content className="content" item md={6} xs={12}>
            {title && <S.Title lightContentArea={lightContentArea}>{parse(title)}</S.Title>}
            {content && <>{parse(content)}</>}
          </S.Content>

          {image &&
          <S.Image className="image" item md={6} xs={12}>
            <img src={image.localFile.childImageSharp.resize.src} alt={image.altText} />
          </S.Image>
          }
        </S.ImageHighlight>
      </S.HighlightContainer>
    </S.Wrapper>
  )
}
export default ImageHighlight