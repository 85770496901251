import React from "react"
import * as S from "./ContentCarousel.styles.jsx"
import parse from "html-react-parser"

const ContentCarousel = ({ items }) => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
    <S.Wrapper>
        <S.Carousel {...settings}>
        {items.map((carouselItem, index) => (
        <>
        <S.CarouselItem key={`items-${index}`} style={{"background-image" : (carouselItem.backgroundImage ? `url(${carouselItem.backgroundImage.localFile.childImageSharp.resize.src})` : "")}}>
            {carouselItem.title && <h5 className="title">{parse(carouselItem.title)}</h5>}
            {carouselItem.description && (
                <div className="description">{parse(carouselItem.description)}</div>
            )}
        </S.CarouselItem>
        </>
        ))}
        </S.Carousel>
    </S.Wrapper>
    )
}
export default ContentCarousel