import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { getPageLayout } from "../../get-layout-utils"

export const query = graphql`
  query PageQuery($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      isFrontPage
      title
      content
      uri
      slug
      parentId
      seo {
        canonical
        title
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          altText
          sourceUrl
          title
        }
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        twitterDescription
        twitterTitle
        twitterImage {
          altText
          sourceUrl
          title
        }
      }
      pageBuilder {
        layouts {
          ... on WpPage_Pagebuilder_Layouts_MiscContent {
            ...MiscContent
          }
          ... on WpPage_Pagebuilder_Layouts_HomepageHero {
            ...HomepageHero
          }
          ... on WpPage_Pagebuilder_Layouts_VideoHero {
            ...VideoHero
          }
          ... on WpPage_Pagebuilder_Layouts_ServicesGrid {
            ...ServicesGrid
          }
          ... on WpPage_Pagebuilder_Layouts_ServiceTiles {
            ...ServiceTiles
          }
          ... on WpPage_Pagebuilder_Layouts_ContentCarousel {
            ...ContentCarousel
          }
          ... on WpPage_Pagebuilder_Layouts_ImageHighlight {
            ...ImageHighlight
          }
          ... on WpPage_Pagebuilder_Layouts_HalfContentGrid {
            ...HalfContentGrid
          }
          ... on WpPage_Pagebuilder_Layouts_BlockContent {
            ...BlockContent
          }
          ... on WpPage_Pagebuilder_Layouts_StaffList {
            ...StaffList
          }
          ... on WpPage_Pagebuilder_Layouts_AllPosts {
            ...AllPosts
          }
          ... on WpPage_Pagebuilder_Layouts_ImageGallery {
            ...ImageGallery
          }
          ... on WpPage_Pagebuilder_Layouts_PressReleases {
            ...PressReleases
          }
          ... on WpPage_Pagebuilder_Layouts_AllResources {
            ...AllResources
          }
        }
        pageConfiguration {
          hideFooter
          hideHeaderItems
          hideFooterContactForm
          lightContentArea
        }
      }
    }
  }
`
const PageTemplate = ({ data }) => {
  const { seo, pageBuilder } = data.wpPage
  const layouts = pageBuilder.layouts || []
  return (
    <Layout {...pageBuilder.pageConfiguration} seo={seo}>
      {layouts.map(layout =>
        getPageLayout(layout, pageBuilder.pageConfiguration.lightContentArea)
      )}
    </Layout>
  )
}

export default PageTemplate
