import styled from "styled-components"
import Container from "@material-ui/core/Container"

export const Wrapper = styled(Container)`
  padding: 0;
  overflow: hidden;
  position: relative;
  max-width: unset;
  width: 100%;

  .copy_container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    max-width: 1280px;

    color: #fff;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, .5);
  }

  h1 {
      color: #fff;
      font-size: 6em;
      max-width: 675px;
      width: calc(100% - 3rem);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 3rem;
      line-height: .8em;
      margin: 0;
      padding: 0;
      small { font-size: .5em; }
  }

  .content {
      color: #fff;
      font-size: 1.7em;
      position: absolute;
      width: calc(100% - 3rem);
      max-width: 590px;
      bottom: 5%;
      right: 3rem;
      text-align: right;

      p { margin: 0; }
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    h1 {
      font-size: 4em;
      max-width: calc(100% - 40px);
      left: 20px
    }

    .content {
      font-size: 1.4em;
      max-width: calc(100% - 40px);
      right: 20px;
    }
  }
`

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${props => props.overlayColor ? props.overlayColor : "rgba(0, 0, 0, .4)"};
      z-index: 3;
  }
`

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
  z-index: 1;
`