import React from "react"
import * as S from "./ServiceTiles.styles.jsx"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { Grid } from "@material-ui/core"

const ServiceTiles = ({ title, items, flip, lightContentArea }) => {
  return (
    <S.Wrapper lightContentArea={lightContentArea}>
      { title && 
        <S.SectionTitle lightContentArea={lightContentArea}>{parse(title)}</S.SectionTitle>
      }

      <div className={`tiles ${flip ? "flip" : ""}`}>
      {items.map((service, index) => (
      <S.Tile className="tile" container justify="center" spacing={0} key={`service-${index}`} lightContentArea={lightContentArea}>
        {service.image &&
        <Grid className="image" item md={6} xs={12}>
            <GatsbyImage image={service.image.localFile.childImageSharp.gatsbyImageData} alt={service.image.altText} />
            {/* <img src={service.image.localFile.childImageSharp.resize.src} alt={service.image.altText} /> */}
        </Grid>
        }

        <Grid className="content" item md={6} xs={12}>
          {service.title && (
            <S.ServiceTitle lightContentArea={lightContentArea}>
              {service.pageLink ? (
                <a href={service.pageLink.url}>{parse(service.title)}</a>
              ) : (
                parse(service.title)
              )}
            </S.ServiceTitle>
          )}

          {service.description && <S.ServiceDescription lightContentArea={lightContentArea}>{parse(service.description)}</S.ServiceDescription>}
        </Grid>

      </S.Tile>
      ))}
      </div>
    </S.Wrapper>
  )
}
export default ServiceTiles