import React from "react"
import * as S from "./ServicesGrid.styles.jsx"
import parse from "html-react-parser"
import { Grid } from "@material-ui/core"

const ServicesGrid = ({ title, items, lightContentArea }) => {

  return (
    <S.Wrapper lightContentArea={lightContentArea}>
        {title &&
        <S.SectionTitle lightContentArea={lightContentArea}>{parse(title)}</S.SectionTitle>
        }

        <Grid container justify="center" spacing={0}>
          {items.map((service, index) => (
            <Grid item md={4} sm={6} xs={12} key={`items-${index}`}>
              <S.ServiceItem style={{"background-image" : (service.backgroundImage ? `url(${service.backgroundImage.localFile.childImageSharp.resize.src})` : "")}}>
                {service.name && <h5 className="title">{parse(service.name)}</h5>}
                {service.description && (
                  <div className="description">{parse(service.description)}</div>
                )}
              </S.ServiceItem>
            </Grid>
          ))}
      </Grid>
    </S.Wrapper>
  )
}
export default ServicesGrid