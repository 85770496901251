import React from "react"
import * as S from "./learn-card.styles.jsx"
import CustomTypography from "../custom-typography/custom-typography.component"
import { textEllipsis } from "../../utils"
import parse from "html-react-parser"
import { Link } from "gatsby"

const LearnCard = ({ image, tag, title, text, isLight, url }) => {
  return (
    <S.Wrapper isLight={isLight}>
      <Link to={url}>
        {image &&
          (image.source_url ? (
            <img
              alt={image.alt_text}
              src={image.source_url}
              style={{ height: "200px", width: "100%" }}
            />
          ) : (
            <S.CustomImage tag="div" img={image} />
          ))}
      </Link>
      <S.TextWrapper>
        {tag && (
          <CustomTypography mb={1} color="tertiary">
            {tag}
          </CustomTypography>
        )}

        {title && <S.Title>{title}</S.Title>}

        {text && (
          <S.Text>
            {textEllipsis(parse(text.replace(/(<([^>]+)>)/gi, "")), 170)}
          </S.Text>
        )}

        <S.ReadMore to={url}>Read More</S.ReadMore>
      </S.TextWrapper>
    </S.Wrapper>
  )
}
export default LearnCard
