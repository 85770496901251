import styled from "styled-components"
import { Typography } from "@material-ui/core"
import SectionImg from "../section-img/section-img.component"
import { Link } from "gatsby"

export const Wrapper = styled.div``

export const CustomImage = styled(SectionImg)`
  height: 200px;
`

export const TextWrapper = styled.div`
  padding: 1.5em;
`

export const Title = styled.h3`
  font-family: ${({ theme }) => theme.fonts.secondaryFont};
  font-size: ${({ theme }) => theme.typography.pxToRem(19)};
  margin-bottom: 1rem;
`

export const Text = styled(Typography)`
  font-size: 0.875rem;
  margin-bottom: 1.5em;
  color: ${({ theme }) => theme.palette.text.content};
`
export const ReadMore = styled(Link)`
  text-decoration: underline;
`
