import styled from "styled-components"
import Container from "@material-ui/core/Container"
import { Grid } from "@material-ui/core"

export const Wrapper = styled(Container)`
  padding: 0;
  background-color: ${props => props.lightContentArea ? "#F4F8FE" : "#000" };
  color: ${props => props.lightContentArea ? "#000" : "#FFF" };
  margin-bottom: 24px;
`

export const Header = styled.div`
  position: relative;
  min-height: ${props => props.headerHeight ? props.headerHeight : "auto" };

  ${({ image, headerHeight }) => image && `
    background-image: url(${image.localFile.childImageSharp.resize.src});
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10vw;
    padding: 2em;

    ${({ theme }) => theme.breakpoints.down("sm")} {
      gap: 7vw;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, .4);
    }

    .title {
      position: relative;
      z-index: 2;
      color: #fff;
      margin: 0;
    }

    .content {
      color: #fff;
      position: relative;
      z-index: 2;
      margin-top: 40px;

      .sep {
        padding: 0 2rem;
      }

      ${({ theme }) => theme.breakpoints.down("sm")} {
        text-align: center;

        .sep {
          display: block;
          text-indent: -9999em;
          overflow: hidden;
          visibility: hidden;
          height: 5px;
        }
      }
    }
  `}

  &::before {

  }

  .title {
    font-size: 3em;
    text-align: center;

    ${({ theme }) => theme.breakpoints.down("sm")} {
      font-size: 2em;
    }
  }
  h2::after { display: none; }
`

export const GridContainer = styled(Grid)`
  margin-top: 2rem;
  padding: 0 16px 16px;
`