import styled from "styled-components"
import Container from "@material-ui/core/Container"

export const Wrapper = styled(Container)`
  margin-bottom: 24px;
  padding: 0 3rem;
`

export const SectionTitle = styled.h3`
    margin-bottom: 20px;
    text-align: left;

    &::after {
        
    }

    ${({ theme }) => theme.breakpoints.down("sm")} {
        text-align: center;
        padding: 0;
    }
`