import React from "react"
import * as S from "./VideoHero.styles.jsx"
import parse from "html-react-parser"

const VideoHero = ({ title, content, video, videoPoster, height, autoplay, overlayColor, classList }) => {
  let full = title && !content ? 'full' : ''
  return (
    <S.Wrapper style={{"height": (height ? height : "60vh")}} className={classList}>
        <S.HeroBg overlayColor={overlayColor}>
            { video ?
              <S.VideoBg 
                  className="bg"
                  autoPlay={autoplay ? "autoplay" : false }
                  loop muted
                  src={video.localFile.publicURL}
                  poster={videoPoster ? videoPoster.localFile.childImageSharp.gatsbyImageData.images.fallback.src : false } 
              />
              : 
                (
                  videoPoster ?
                  <img className="bg" src={videoPoster.localFile.childImageSharp.gatsbyImageData.images.fallback.src} alt="" /> : false
                )
            }
        </S.HeroBg>

        <div className="copy_container">
            <h1 className={full}>{parse(title)}</h1>
            {content &&
              <div className="content">{parse(content)}</div>
            }
        </div>
    </S.Wrapper>
  )
}
export default VideoHero
