import React from "react"
import * as S from "./HalfContentGrid.styles.jsx"
import parse from "html-react-parser"
import { Grid } from "@material-ui/core"

const HalfContentGrid = ({ title, content, image, headerHeight, contentA, contentB, lightContentArea, containerMaxWidth, pixelBorder, backgroundPosition }) => {
  return (
    <S.Wrapper maxWidth={containerMaxWidth} lightContentArea={lightContentArea} className={pixelBorder ? "pixel-border" : null}>
        <S.Header lightContentArea={lightContentArea} image={image} headerHeight={headerHeight} backgroundPosition={backgroundPosition}>
        {title &&
          <h2 className="title">{parse(title)}</h2>
        }

        {content && <div className="content">{parse(content)}</div>}
        </S.Header>

        {contentA && contentB && 
        <S.GridContainer container justify="space-around" spacing={2}>
          {contentA &&
          <Grid className="content_a" item md={5} xs={12}>
            {parse(contentA)}
          </Grid>
          }
          {contentB &&
          <Grid className="content_b" item md={5} xs={12}>
            {parse(contentB)}
          </Grid>
          }
        </S.GridContainer>
        }
    </S.Wrapper>
  )
}
export default HalfContentGrid