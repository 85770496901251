import styled from "styled-components"
import Container from "@material-ui/core/Container"

export const Wrapper = styled(Container)`
  padding: 0;
  overflow: hidden;
  position: relative;
  max-width: unset;
  width: 100%;
  margin-bottom: 2rem;

  &.nomargin {
    margin-bottom: 0;
  }

  .copy_container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    max-width: 1280px;

    color: #fff;
    text-shadow: 1px 2px 2px rgba(0, 0, 0, .5);
  }

  h1 {
      color: #fff;
      font-size: 4em;
      max-width: 47vw;
      width: 100%;
      position: absolute;
      top: calc(50% + 40px);
      transform: translateY(-50%);
      left: 3rem;
      line-height: 1em;
      small { font-size: .5em; }

      &.full {
        max-width: calc(100% - 30px);
      }
  }

  .content {
      color: #fff;
      font-size: 1.4em;
      position: absolute;
      width: 100%;
      max-width: 40vw;
      bottom: 10%;
      right: 3rem;
      text-align: right;
  }

  &.bg-top-right {
    .bg {
      object-position: top right;
    }
  }

  &.bg-top-left {
    .bg {
      object-position: top left;
    }
  }

  &.bg-bottom-right {
    .bg {
      object-position: bottom right;
    }
  }

  &.bg-bottom-left {
    .bg {
      object-position: bottom left;
    }
  }

  ${({ theme }) => theme.breakpoints.down("sm")} {
    .copy_container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      padding: 0 3rem;
    }

    h1 {
      position: static;
      transform: unset;
      max-width: 100%;
      font-size: 3.2em;
      margin-bottom: .75em;
    }

    .content {
      position: static;
      max-width: 100%;
      font-size: 1.2em;
      text-align: left;
    }
  }

  ${({ theme }) => theme.breakpoints.down("xs")} {
    .copy_container {
      padding: 90px 15px 0;
    }

    h1 {
      font-size: 2em;
      text-align: center;
    }

    .content {
      text-align: center;
      font-size: 1.1em;
    }
  }

  .bg {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: 1;
  }
`

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${props => props.overlayColor ? props.overlayColor : "rgba(0, 0, 0, .6)"};
      z-index: 3;
  }
`

export const VideoBg = styled.video`
  background: #232a34;
`