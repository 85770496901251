import React from "react"
import * as S from "./StaffList.styles.jsx"
import parse from "html-react-parser"
import { Grid, Divider } from "@material-ui/core"

const StaffList = ({ title, people, lightContentArea }) => {
  return (
    <S.Wrapper lightContentArea={lightContentArea}>
        {title &&
          <h2 className="title">{parse(title)}</h2>
        }

        <div className="peopleList">
          {people.map((person, index) => (
          <S.Person container justify="space-between" alignItems="center" spacing={2} key={`person-${index}`}>
            <Grid className="portrait" item md={3} sm={5} xs={12}>
              {person.portrait && 
              <img src={person.portrait?.localFile.childImageSharp.resize.src} alt={person.portrait?.altText} width="180" />
              }
            </Grid>
            <Divider lightContentArea={lightContentArea} className="divider" orientation="vertical" flexItem style={{marginRight:"-1px"}} />
            <Grid item md={8} sm={6} xs={12}>
              <S.Name lightContentArea={lightContentArea}>{parse(person.name)} <small>{parse(person.title)}</small></S.Name>
              <S.Biography className="bio">{parse(person.biography)}</S.Biography>
            </Grid>
          </S.Person>
          ))}
        </div>
    </S.Wrapper>
  )
}
export default StaffList